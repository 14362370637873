import * as React from 'react';

function ChangePaginationIcon({ fill = '#A8A8A8', direction = 'left' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
    >
      {direction === 'left' ? (
        <path
          d="M11.0126 14.8044L9.95886 15.6309L0 7.8193L0.00484734 7.8155L0 7.8117L9.95886 0.000136401L11.0126 0.826634L2.10253 7.8155L11.0126 14.8044Z"
          fill={fill}
        />
      ) : (
        <path
          d="M0.000139164 14.8044L1.05383 15.6309L11.0127 7.8193L11.0078 7.8155L11.0127 7.8117L1.05383 0.000137246L0.000137942 0.826634L8.91016 7.8155L0.000139164 14.8044Z"
          fill={fill}
        />
      )}
    </svg>
  );
}

export default ChangePaginationIcon;
