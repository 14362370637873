/* eslint-disable react/no-danger */
import * as React from 'react';
import { articleContainer } from './news-article.module.css';

function NewsArticle({ article }) {
  const { body, title, id } = article;
  return (
    <div
      className={`
            ${articleContainer}
            cursor-pointer
            xl:w-[359px]
            lg:w-[300px]
            mobile:w-full
            lg:h-[300px]
            mobile:h-auto
            font-roboto
            p-[26px]
            lg:pb-[40px]
            mobile:pb-[30px]
        `}
      role="button"
      tabIndex="0"
      onClick={() => {
        window.location.href = `${window.location.origin}/news/detail/${id}`;
      }}
      onKeyUp={() => {
        window.location.href = `${window.location.origin}/news/detail/${id}`;
      }}
    >
      <h3
        className="
                text-neutral-500
                text-32
                mobile:text-24
                font-bold
                leading-[35px]
                mb-[30px]
                line-clamp-2
            "
      >
        {title}
      </h3>
      <div
        className="
                text-neutral-400
                text-18
                mobile:text-[16px]
                leading-[30px]
                line-clamp-5
                mobile:line-clamp-4
            "
        dangerouslySetInnerHTML={{
          __html: body.body,
        }}
      />
    </div>
  );
}

export default NewsArticle;
