import * as React from 'react';

import paginationIcon from '../../../../../images/paginationIcon.png';
import activePaginationIcon from '../../../../../images/activePaginationIcon.png';
import ChangePaginationIcon from './components/change-pagination-icon';

function NewsArticlePagination({
  activeChunkIndex,
  setActiveChunkIndex,
  news,
  chunkedArticles,
}) {
  return (
    <div
      className={`
                    ${news.length > 3 ? 'flex' : 'hidden'}
                    mt-[35px]
                    justify-center
                    items-center
                `}
    >
      <button
        className="
                        mr-[10px]
                    "
        type="button"
        onClick={() => {
          if (activeChunkIndex > 0) {
            setActiveChunkIndex(activeChunkIndex - 1);
          }
        }}
      >
        <ChangePaginationIcon />
      </button>
      {chunkedArticles.map((article, idx) => (
        <button
          key={`${article[0].node.id}-pagination-button`}
          type="button"
          className={`
                                h-[12px]
                                ${
                                  idx === activeChunkIndex
                                    ? 'w-[40px] mr-[16px]'
                                    : 'w-[12px] mr-[10px]'
                                }
                            `}
          onClick={() => setActiveChunkIndex(idx)}
        >
          <img
            src={idx === activeChunkIndex ? activePaginationIcon : paginationIcon}
            alt={`Pagination Icon ${idx}`}
          />
        </button>
      ))}
      <button
        type="button"
        onClick={() => {
          if (activeChunkIndex < chunkedArticles.length - 1) {
            setActiveChunkIndex(activeChunkIndex + 1);
          }
        }}
      >
        <ChangePaginationIcon direction="right" />
      </button>
    </div>
  );
}

export default NewsArticlePagination;
