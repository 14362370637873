import * as React from 'react';

import { pipeReflection } from './metric.module.css';

function Metric({ explanation, idx, img, metric }) {
  return (
    <div
      className={`
      relative
      flex
      flex-col
      shrink-0
      xl:basis-[257px]
      lg:basis-[300px]
      mobile:basis-[237px]
      mobile:items-center
      ${idx === 1 ? 'xl:basis-[470px]' : ''}
    `}
    >
      <div
        className={`
        flex
        flex-col
        py-0
        mobile:p-0
        justify-end
        h-[179px]
        ${
          idx === 0
            ? `
          xl:pr-[130px]
          xl:pl-[20px]
          lg:pl-0
          lg:pr-[45px]
          xl:w-[386px]
          lg:w-[295px]
        `
            : ''
        }
        ${
          idx === 1
            ? `
          xl:mx-auto
          lg:px-[90px]
          xl:w-[470px]
          lg:w-[390px]
          border-white
          border-2
          border-solid
          border-t-0
          border-b-0
          mobile:border-0
        `
            : ''
        }
        ${
          idx === 2
            ? `
            xl:pl-[120px]
            lg:pl-[52px]
            xl:pr-[60px]
            lg:pr-0
          `
            : ''
        }
      `}
      >
        <h2
          className="
          font-rubik
          font-bold
          text-[80px]
          leading-[65px]
          text-white
          text-center
        "
        >
          {metric}
        </h2>
        <h3
          className={`
          xl:w-full
          font-roboto
          xl:text-24
          lg:text-17
          mobile:text-24
          leading-[30px]
          text-center
          text-white
          pt-[25px]
          mobile:pt-[25px]
          ${idx === 0 ? 'mobile:px-[25px]' : 'mobile:px-[75px]'}
          mobile:pb-0
          ${
            idx === 0
              ? `
              lg:w-[200px]
              mx-auto
            `
              : ''
          }
          ${
            idx === 2
              ? `
                lg:w-[150px]
                mx-auto
              `
              : ''
          }
        `}
        >
          {explanation}
        </h3>
      </div>
      <img
        src={img}
        alt={explanation}
        className={`
        mx-auto
        relative
        top-[30px]
        ${
          idx === 0
            ? `xl:w-[236px]
               lg:w-[170px]
               mobile:hidden
               xl:ml-[19px]
               lg:ml-[39px]
               mr-0
              `
            : ''
        }
        ${idx === 1 ? 'xl:w-[186px] lg:w-[129px] mobile:hidden' : ''}
        ${
          idx === 2
            ? `xl:w-[134px]
             lg:w-[98px]
             mobile:hidden
             xl:left-[30px]
             lg:left-[27px]
          `
            : ''
        }
      `}
      />
      {idx !== 2 ? (
        <span
          className={`
          ${pipeReflection}
          inline-block
          absolute
          right-[-46px]
          bottom-0
          w-[94px]
          h-[2px]
          rotate-90
          mobile:hidden
          ${idx === 0 ? 'right-[-47px]' : ''}
        `}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Metric;
