import * as React from 'react';

function Section2({ videoLink }) {
  return (
    <section
      className="
      flex
      content-center
      justify-center
      xl:pt-[45px]
      lg:pt-[90px]
      lg:pb-[88px]
      mobile:pt-[0]
      mobile:px-[15px]
    "
    >
      <div
        className="
        relative
        overflow-hidden
        xl:w-[1114px]
        xl:h-[750px]
        xl:pt-0
        lg:w-full
        mobile:w-full
        mobile:pt-[56.25%]
        lg:pt-[56.25%]
      "
      >
        <iframe
          className="absolute top-0 right-0 bottom-0 left-0 w-full h-full"
          src={videoLink}
          title="welcome"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
    </section>
  );
}

export default Section2;
