import * as React from 'react';

function Card({ alt, children, img, title }) {
  return (
    <div
      className="
      w-full
      h-full
      duration-[0.3s]
      bg-white
      rounded-[50px]
      hover:rounded-[30px]
      p-0
      hover:shadow-[0_0_75px_4px_rgba(0,0,0,0.15)]
      hover:duration-[0.3s]
      mobile:hover:rounded-0
      mobile:hover:shadow-none
      lg:hover:shadow-[0_0_35px_4px_rgba(0,0,0,0.15)]
    "
    >
      <img
        className="
        mx-auto
        block
        mobile:hidden
      "
        src={img}
        alt={alt}
      />
      <h3
        className="
        font-roboto
        text-32
        mobile:text-24
        font-bold
        leading-[35px]
        text-center
        mobile:text-left
        text-primary
        xl:mt-[50px]
        mobile:mt-0
        mobile:mb-[40px]
        lg:text-24
      "
      >
        {title}
      </h3>
      <img
        className="
        hidden
        mobile:block
      "
        src={img}
        alt={alt}
      />
      {children}
    </div>
  );
}

export default Card;
