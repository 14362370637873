import * as React from 'react';
import { useState } from 'react';
import NewsArticle from './components/news-article/news-article';

import NewsArticlePagination from './components/news-article-pagination/news-article-pagination';

function Section3({ pageData, news }) {
  const newsPerPage = 3;
  const chunkedArticles = news.reduce((resultArray, article, idx) => {
    const chunkIndex = Math.floor(idx / newsPerPage);

    if (!resultArray[chunkIndex]) {
      resultArray.push([]);
    }

    resultArray[chunkIndex].push(article);

    return resultArray;
  }, []);
  const [activeChunkIndex, setActiveChunkIndex] = useState(0);

  const swipeThreshold = 150;
  const swipeTime = 500;
  let dist;
  let startX;
  let startY;
  let startTime;
  let elapsedTime;

  function handleSwipe(isSwipe, isRightSwipe) {
    if (isSwipe) {
      if (isRightSwipe && activeChunkIndex > 0) {
        setActiveChunkIndex(activeChunkIndex - 1);
      }
      if (!isRightSwipe && activeChunkIndex < chunkedArticles.length - 1) {
        setActiveChunkIndex(activeChunkIndex + 1);
      }
    }
  }

  function touchStart(e) {
    const touchobj = e.changedTouches[0];
    dist = 0;
    startX = touchobj.pageX;
    startY = touchobj.pageY;
    startTime = new Date().getTime(); // record time when finger first makes contact with surface
  }

  function touchEnd(e) {
    const touchobj = e.changedTouches[0];
    dist = touchobj.pageX - startX; // get total dist traveled by finger while in contact with surface
    elapsedTime = new Date().getTime() - startTime; // get time elapsed
    // check that elapsed time is within specified, horizontal dist traveled >= threshold, and vertical dist traveled <= 100
    const isSwipe = elapsedTime <= swipeTime && Math.abs(dist) > swipeThreshold;
    const isRightSwipe =
      elapsedTime <= swipeTime &&
      dist > swipeThreshold &&
      Math.abs(touchobj.pageY - startY) <= 100;
    handleSwipe(isSwipe, isRightSwipe);
  }

  return (
    <section
      className="
      w-full
      xl:mb-[122px]
      lg:mb-[140px]
      mobile:mb-[54px]
      mobile:mt-[26px]
      mobile:px-[15px]
    "
    >
      <div
        className="
                xl:w-[1121px]
                lg:w-[944px]
                mx-auto
            "
      >
        <h2
          style={{ overflow: 'hidden' }}
          id="news-title"
          className="
                    text-primary
                    text-40
                    font-rubik
                    font-bold
                    leading-[65px]
                    mb-[50px]
                    mobile:mb-[33px]
                    mobile:pl-[26px]
                "
        >
          {pageData.newsTitle}
        </h2>
        <div
          className="
                    grid
                    lg:grid-cols-3
                    mobile:grid-cols-1
                    gap-x-[22px]
                    mobile:gap-x-0
                    mobile:gap-y-[16px]
                "
          onTouchStart={(e) => touchStart(e)}
          onTouchEnd={(e) => touchEnd(e)}
        >
          {chunkedArticles[activeChunkIndex].map((article) => (
            <NewsArticle key={article.node.id} article={article.node} />
          ))}
        </div>
        <NewsArticlePagination
          news={news}
          chunkedArticles={chunkedArticles}
          setActiveChunkIndex={setActiveChunkIndex}
          activeChunkIndex={activeChunkIndex}
        />
      </div>
    </section>
  );
}

export default Section3;
