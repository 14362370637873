import * as React from 'react';
import Section0Bottom from './components/section-0-bottom/section-0-bottom';
import Section0Top from './components/section-0-top/section-0-top';

function Section0({ pageData }) {
  const {
    card1Title,
    card1Text,
    card2Title,
    card2Text,
    heading,
    mobileHeading,
    subheading,
    heading2,
    subheading2,
  } = pageData;

  return (
    <section className="pt-[100px] mobile:pt-[50px] mobile:px-[15px]">
      <div
        className="
        xl:w-[1234px]
        lg:w-[916px]
        mobile:w-full
        smMobile:w-full
        mx-auto
      "
      >
        <Section0Top
          heading={heading.heading}
          mobileHeading={mobileHeading.mobileHeading}
          subheading={subheading.subheading}
          heading2={heading2.heading2}
          subheading2={subheading2.subheading2}
        />
        <div
          className={`
          lg:hidden
          mobile:block
          w-full
          h-0
          mt-[75px]
          mobile:mt-[48px]
          mx-0
          mb-[30px]
          mobile:border-0
          mobile:border-t-[2px]
          mobile:border-solid
          mobile:border-[rgba(57,57,57,0.25)]
        `}
        />
        <Section0Bottom
          card0Title={card1Title}
          card0List={card1Text}
          card1Title={card2Title}
          card1List={card2Text}
        />
      </div>
    </section>
  );
}

export default Section0;
