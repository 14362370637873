import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout/layout';

import handshake from '../images/headerIndex.svg';
import Section0 from '../page-components/index/section-0/section-0';
import Section1 from '../page-components/index/section-1/section-1';
import Section2 from '../page-components/index/section-2/section-2';
import Section3 from '../page-components/index/section-3/section-3';

function IndexPage({ data, location }) {
  const pageData = data.contentfulHomepage;
  const newsData = data.allContentfulNewsroomArticle;
  const headerContent = {
    heading: 'Accelerating Federal Health Partnerships',
    subheading:
      'Health Information Vision Exchange (HIVE) is a virtual gathering place where government intersects with cutting-edge industry - a space that promotes innovation in the federal health space by making the contract process quicker and more accessible.',
    image: handshake,
    alt: 'Shaking on a Deal',
  };

  return (
    <Layout
      title="Health Information Vision Exchange"
      headerContent={headerContent}
      location={location}
    >
      <Section0 pageData={pageData} />
      <Section1 pageData={pageData} />
      <Section2 videoLink={pageData.videoLink} />
      <Section3 pageData={pageData} news={newsData.edges} />
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  {
    contentfulHomepage {
      heading {
        heading
      }
      mobileHeading {
        mobileHeading
      }
      subheading {
        subheading
      }
      heading2 {
        heading2
      }
      subheading2 {
        subheading2
      }
      card1Title
      card1Text
      card2Title
      card2Text
      videoLink
      metricHeading
      metricHeadingMobile
      metrics
      metricExplanations
      newsTitle
    }
    allContentfulNewsroomArticle(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          body {
            body
            childMarkdownRemark {
              html
            }
          }
          publishDate(formatString: "MM/DD/YYYY")
          title
        }
      }
      totalCount
    }
  }
`;
