/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import Card from './components/card/card';
import JoinTheConversation from '../../../../../components/join-the-conversation-button/join-the-conversation-button';

import { cardBtnWrapper, cardContainer } from './section-0-bottom.module.css';

import card0Img from '../../../../../images/business-person-facing-right.svg';
import card1Img from '../../../../../images/business-person-facing-left.svg';

function Section0Bottom({ card0List, card1List, card0Title, card1Title }) {
  const cardContainerClassNames = `
    ${cardContainer}
    xl:w-[555px]
    lg:w-[479px]
    xl:h-[746px]
    lg:h-[762px]
    mx-auto
    xl:mb-[75px]
    mobile:w-full
    mobile:h-auto
    mobile:relative
  `;

  const ulClassNames = `
    lg:mt-[21px]
    list-disc
    text-gray
    font-roboto
    lg:text-18
    leading-[30px]
    text-left
    mobile:w-[250px]
    mobile:text-[16px]
  `;

  const cardBtnClassNames = `
    ${cardBtnWrapper}
    text-center
    my-[36px]
    duration-[0.3s]
  `;

  return (
    <div
      className="
      flex
      xl:gap-[70px]
      gap-[35px]
      mt-[50px]
      lg:mb-[50px]
      mobile:block
      mobile:mt-0
    "
    >
      <div
        className={`
        ${cardContainerClassNames}
        lg:mr-[9px]
        mobile:mr-0
        mobile:mb-[50px]
      `}
      >
        <Card title={card0Title} img={card0Img} alt="Business Person Facing Right">
          <ul
            className={`
            ${ulClassNames}
            xl:px-[83px]
            xl:pl-[135px]
            lg:px-[33px]
            mobile:text-[16px]
            mobile:w-[250px]
            mobile:p-0
            mobile:float-right
          `}
          >
            {card0List.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
          <div
            className={`
            ${cardBtnClassNames}
            mobile:hidden
          `}
          >
            <JoinTheConversation />
          </div>
        </Card>
      </div>
      <div
        className={`
        ${cardContainerClassNames}
        mobile:mb-[100px]
      `}
      >
        <Card title={card1Title} img={card1Img} alt="Business Person Facing Left">
          <ul
            className={`
              ${ulClassNames}
              xl:px-[75px]
              xl:pl-[114px]
              lg:px-[46px]
              lg:pl-[24px]
              mobile:pl-[14px]
              mobile:relative
              mobile:left-[15px]
            `}
          >
            {card1List.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
          <div
            className={`
            ${cardBtnClassNames}
            mobile:hidden
          `}
          >
            <JoinTheConversation />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Section0Bottom;
