import * as React from 'react';
import JoinTheConversation from '../../../../../components/join-the-conversation-button/join-the-conversation-button';

import weBelieveHandshake from '../../../../../images/we-believe-handshake.svg';
import { btnWrapper } from './section-0-top.module.css';

function Section0Top({ heading, subheading, heading2, subheading2 }) {
  return (
    <div className="flex shrink-0 mx-auto my-0">
      <img
        className="
        lg:inline-block
        mobile:hidden
        h-auto
        shrink-0
        xl:basis-[512px]
        lg:basis-[326px]
        self-start
        relative
        top-0
      "
        src={weBelieveHandshake}
        alt="We Believe"
      />
      <div
        className="
        xl:w-[722px]
        lg:w-[590px]
      "
      >
        <h2
          className="
          font-rubik
          font-bold
          text-40
          text-primary
          leading-[65px]
          mb-[22px]
        "
        >
          {heading}
        </h2>
        <p
          className="
          font-roboto
          lg:text-18
          mobile:text-17
          text-400
          leading-[30px]
          m-0
        "
        >
          {subheading}
        </p>
        <img
          className="
          lg:hidden
          mobile:block
          mt-[50px]
          mx-auto
          mb-0
          w-[243px]
        "
          src={weBelieveHandshake}
          alt="We Believe"
        />
        <h3
          className="
          font-roboto
          font-bold
          text-primary
          lg:text-32
          mobile:text-24
          mobile:text-left
          leading-[30px]
          mx-0
          mt-[78px]
          mb-[50px]
          mobile:mt-[50px]
          mobile:mb-[25px]
        "
        >
          {heading2}
        </h3>
        <p
          className="
          font-roboto
          lg:text-18
          mobile:text-17
          text-400
          leading-[30px]
          mb-[48px]
        "
        >
          {subheading2}
        </p>
        <span className={btnWrapper}>
          <JoinTheConversation />
        </span>
      </div>
    </div>
  );
}

export default Section0Top;
